<template lang="html">
    <div class="top-news">
      <div class="row">
        <div class="col-2">
            <h3 class="title">
              Information
            </h3>
            <h2 class="sub-title">
              {{$t('top_page.list')}}
            </h2>
            <div class="show-more">
              <label>一覧</label>
              <router-link class="btn-arrow" :to="{ name: 'my-page-list-information'}">
                <span class="icon-arrow-right"></span>
              </router-link>
            </div>
        </div>
        <div class="col-10">
            <ul class="list">
                <li v-for="(item, index) in informationsList.data" :key="index">
                    <Item :item="item" />
                </li>
            </ul>
        </div>
      </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Item from "@/modules/pc/mypage/information/components/item.vue";
export default {
  components: {
    Item
  },
  data() {
    return {
      filters: {
        page: 1,
        limit: 3,
      },
    };
  },
  computed: {
    ...mapGetters({
      informationsList: "mypage/getInformation",
    }),
  },
  created() {
    this.fetchMypageInformaiton(this.filters);
  },
  methods: {
    ...mapActions({
      fetchMypageInformaiton: "mypage/fetchMypageInformation",
    }),
  },
};
</script>
<style scoped>

.custom-text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>