<template>
    <div>
        <div class="pl-1 pr-1">
            <Breadcrumb :breadcrumb="breadcrumbItems"/>
        </div>
        <div class="main my-page p-0">
            <div class="container p-0">
                <Information/>
            </div>
            <div class="row mypage-schedule">
                <div class="col-md-12 col-lg-2">
                    <h2 class="sub-title">{{ $t("mypage.calendar") }}</h2>
                </div>
                <div class="col-md-12 col-lg-10">
                    <LessonSchedule />
                </div>
            </div>
        </div>
        <MetaTags 
            title="マイページ｜ResMom相談online"
            description="リセマム相談オンラインのマイページです。｜リセマム相談onlineは、日本最大級の教育情報Webメディア「リセマム」が提供する教育相談サービスです。幼児教育、小学校受験、中学受験、高校受験、大学受験、海外進学、国際教育、グローバル教育、進路相談、進路コンサル、留学、子育て相談や悩みについて専門家や先輩保護者などに「すぐに」「気軽に」「どこでも」相談いただけます。"
        />
    </div>
</template>

<script>
import Information from "./components/information.vue";
import LessonSchedule from "@modules/pc/mypage/dashboard/components/lesson-schedule.vue";

export default {
    data() {
        return {
            breadcrumbItems: [
                {
                    title: this.$t("menu.breadcrumb.my_page"),
                    link: "/my-page#",
                },
            ],
        };
    },
    components: {
        Information,
        LessonSchedule,
    },
};
</script>

<style lang="scss">
.my-page {
    width: 100% !important;
}
</style>